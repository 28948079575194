import {Show, ShowId} from '@/components/show-page/types';
import {v4 as uuid} from 'uuid';

import WhiteRabbitLogo from 'public/images/white-rabbit-credits-logo.png';
import HouseOfLeeLogo from 'public/images/HOL_logo_white.png';
import DominionXLogo from 'public/images/dominion-x-logo.png';

export const WHITE_RABBIT_CURRENT_EPISODE_ID = 4;

export const SHOWS_BY_ID: Record<ShowId, Show> = {
  'white-rabbit': {
    id: 'white-rabbit',
    title: 'White Rabbit',
    logoImage: WhiteRabbitLogo,
    watchNextShowId: 'dominionx',
    thumbnailImageSrc:
      'https://storage.googleapis.com/shibuya-white-rabbit/white-rabbit-preview.png',
    creators: 'pplpleasr and Maciej Kuciara',
    description:
      'White Rabbit is an interactive animated film that explores themes of self-discovery, fear of the unknown, technology, and the complexities of identity.',
    heroVideos: [
      {
        type: 'video/mp4',
        src: 'https://storage.googleapis.com/shibuya-white-rabbit/white_rabbit_show_page_hero.mp4',
      },
    ],
    learnMore: {
      url: '/shows/white-rabbit/faq',
      text: 'Chapter 4 launch FAQ',
    },
    timelineChapters: [
      {
        id: uuid(),
        title: 'Chapter 1',
        events: [
          {
            id: uuid(),
            date: 'MAR 1, 2022',
            title: 'Chapter 1 drop',
            description:
              'The first chapter of White Rabbit premiered on March 1, 2022 with 48 seconds of animation. It sold out 5000 producer passes in 22 minutes and raised $1.2m in funding.',
            imageSrc: '/images/white-rabbit/timeline/normal/chapter-1-drop.jpg',
            wideImageSrc:
              '/images/white-rabbit/timeline/wide/chapter-1-drop.jpg',
          },
          {
            id: uuid(),
            date: 'MAR 2, 2022',
            title: 'Chapter 1 ending vote',
            description:
              'There was a total of 3105 votes with the left door winning with 4433 votes',
            imageSrc:
              '/images/white-rabbit/timeline/normal/chapter-1-ending-vote.jpg',
            wideImageSrc:
              '/images/white-rabbit/timeline/wide/chapter-1-ending-vote.jpg',
          },
          {
            id: uuid(),
            date: 'MAR 2, 2022',
            title: 'Seed phrase scavenger hunt',
            description:
              'Secret words were scattered throughout the chapter and the first viewer to find them got to keep a lucky bag of NFTs',
            imageSrc:
              '/images/white-rabbit/timeline/normal/seed-phrase-scavenger-hunt.png',
            wideImageSrc:
              '/images/white-rabbit/timeline/wide/seed-phrase-scavenger-hunt.png',
            cta: {
              text: 'View blog post',
              href: 'https://twitter.com/shibuyafilm/status/1499047644798324739?s=20',
            },
          },
          {
            id: uuid(),
            date: 'MAR 29, 2022',
            title: 'Main character name vote',
            description:
              'Community voted using $WRAB on the name of the main character with “Mirai” winning the vote (47.3%)',
            imageSrc:
              '/images/white-rabbit/timeline/normal/main-character-name-vote.jpg',
            wideImageSrc:
              '/images/white-rabbit/timeline/wide/main-character-name-vote.jpg',
            cta: {
              text: 'View voting results',
              href: 'https://snapshot.org/#/pplpleasr.eth/proposal/0xbdb32d1666d5484501ef0da87ed6c50d2fac32167eace0312c0e473880725fe8',
            },
          },
          {
            id: uuid(),
            date: 'APR 1, 2022',
            title: 'Mirai on the cover of Vogue Taiwan',
            description:
              'Mirai was the first ever anime character to grace the cover of a Vogue magazine, the issue came out in April 2022 and caught the eyes of many. Inside the issue also features an interview with pplpleasr who talks about Shibuya and White Rabbit, as well as the meaning of the cover.',
            imageSrc:
              '/images/white-rabbit/timeline/normal/mirai-vogue-cover.jpg',
            wideImageSrc:
              '/images/white-rabbit/timeline/wide/mirai-vogue-cover.jpg',
          },
        ],
      },
      {
        id: uuid(),
        title: 'Chapter 2',
        events: [
          {
            id: uuid(),
            date: 'JUN 8, 2022',
            title: 'Chapter 2 drop',
            description:
              '3.5 mins released with 3356 producer passes sold and 255k raised in funding.',
            imageSrc: '/images/white-rabbit/timeline/normal/chapter-2-drop.jpg',
            wideImageSrc:
              '/images/white-rabbit/timeline/wide/chapter-2-drop.jpg',
          },
          {
            id: uuid(),
            date: 'JUN 9, 2022',
            title: 'Chapter 2 ending vote',
            description:
              '“Follow the fox” won with 41.04% of the votes, “go to the drop” in second with 37.74%, and “follow the lady” with 21.22%.',
            imageSrc:
              '/images/white-rabbit/timeline/normal/chapter-2-ending-vote.jpg',
            wideImageSrc:
              '/images/white-rabbit/timeline/wide/chapter-2-ending-vote.jpg',
          },
          {
            id: uuid(),
            date: 'JUN 10, 2022',
            title: 'Chapter 2 airdrop',
            description:
              'Users who staked their producer pass in the middle path leading to the animation of the mysterious package received an airdrop to their wallets.',
            imageSrc:
              '/images/white-rabbit/timeline/normal/chapter-2-airdrop.jpg',
            wideImageSrc:
              '/images/white-rabbit/timeline/wide/chapter-2-airdrop.jpg',
          },
          {
            id: uuid(),
            date: 'JUL 22, 2022',
            title: 'Launch partner on Coinbase NFT',
            description:
              'Coinbase launched its NFT platform and White Rabbit was a launch partner. The drop included NFT posters which can be redeemed for a physical poster.',
            imageSrc:
              '/images/white-rabbit/timeline/normal/launch-coinbase-nft.jpg',
            wideImageSrc:
              '/images/white-rabbit/timeline/wide/launch-coinbase-nft.jpg',
          },
          {
            id: uuid(),
            date: 'SEP 9, 2022',
            title: 'Azuki casting',
            description:
              'White Rabbit producers and Azuki holders could cast and choose which Azuki character would appear in Chapter 3. AZUKI #3602 was the final winner with 121 votes.',
            imageSrc: '/images/white-rabbit/timeline/normal/azuki-casting.jpg',
            wideImageSrc:
              '/images/white-rabbit/timeline/wide/azuki-casting.jpg',
            cta: {
              text: 'See top 10',
              href: '/shows/white-rabbit/azuki-casting',
            },
          },
          {
            id: uuid(),
            date: 'OCT 11, 2022',
            title: 'Cookshop opens',
            description:
              'The airdrop from chapter 2 revealed themselves to be a collection of steak NFTs hand drawn by pplpleasr. Users can stake their stakes to earn $WRAB between chapters.',
            imageSrc: '/images/white-rabbit/timeline/normal/cookshop-opens.jpg',
            wideImageSrc:
              '/images/white-rabbit/timeline/wide/cookshop-opens.jpg',
            cta: {
              text: 'Learn more',
              href: 'https://shibuyaxyz.substack.com/p/introducing-the-cookshop',
            },
          },
        ],
      },
      {
        id: uuid(),
        title: 'Chapter 3',
        events: [
          {
            id: uuid(),
            date: 'JAN 10, 2023',
            title: 'Chapter 3 drop',
            description:
              'Chapter 3 producer passes were distributed for free only to existing producers, specifically those who staked chapter 1 & 2 producer passes in pairs.',
            imageSrc: '/images/white-rabbit/timeline/normal/chapter-3-drop.jpg',
            wideImageSrc:
              '/images/white-rabbit/timeline/wide/chapter-3-drop.jpg',
          },
          {
            id: uuid(),
            date: 'JAN 10-17, 2022',
            title: 'The floor is lava',
            description:
              'Chapter 3 interactivity was an in-film gaming experience where viewers could connect their wallet and stake a ch3 producer pass to play the game.',
            imageSrc:
              '/images/white-rabbit/timeline/normal/the-floor-is-lava.jpg',
            wideImageSrc:
              '/images/white-rabbit/timeline/wide/the-floor-is-lava.jpg',
            cta: {
              text: 'Watch video',
              href: 'https://www.shibuya.film/shows/white-rabbit/completed/3',
            },
          },
          {
            id: uuid(),
            date: 'JAN 23, 2022',
            title: 'White Rabbit Key',
            description:
              'For each win in the Lava game the user received a key NFT. The key currently unlocks behind the scenes channels in the White Rabbit discord amongst other utilities.',
            imageSrc:
              '/images/white-rabbit/timeline/normal/white-rabbit-key.jpg',
            wideImageSrc:
              '/images/white-rabbit/timeline/wide/white-rabbit-key.jpg',
          },
          {
            id: uuid(),
            date: 'FEB 10, 2023',
            title: 'Linkin Park Music Video',
            description:
              'White Rabbit was featured in Linkin Park’s new music video “Lost”, directed by pplpleasr and Maciej Kuciara. The video on YouTube surpassed 35 million views within 2 months.',
            imageSrc:
              '/images/white-rabbit/timeline/normal/linkin-park-music-video.jpg',
            wideImageSrc:
              '/images/white-rabbit/timeline/wide/linkin-park-music-video.jpg',
            cta: {
              text: 'Watch video',
              href: 'https://www.youtube.com/watch?v=7NK_JOkuSVY',
            },
          },
          {
            id: uuid(),
            date: 'May 15, 2023',
            title: 'Mirai Poster Claim',
            description:
              'Mirai Poster NFT holders were able to redeem a physical poster corresponding to their NFT type.',
            imageSrc:
              '/images/white-rabbit/timeline/normal/launch-coinbase-nft.jpg',
            wideImageSrc:
              '/images/white-rabbit/timeline/wide/launch-coinbase-nft.jpg',
            cta: {
              text: 'Poster claim',
              href: 'https://www.shibuya.film/redeem-poster',
            },
          },
          {
            id: uuid(),
            date: 'July 18, 2023',
            title: 'Faction NFT Airdrop',
            description:
              'White Rabbit Faction NFT snapshot is announced. Holders of first 3 Producer Pass chapters are eligible for this NFT airdrop via a snapshot conducted on July 24th, 2023 5PM EST. Faction NFTs will have exclusive benefits with regards to White Rabbit interactivity and bonus $WRAB distribution.',
            imageSrc: '/images/white-rabbit/timeline/normal/faction-nft.jpg',
            wideImageSrc:
              '/images/white-rabbit/timeline/normal/faction-nft.jpg',
            cta: {
              text: 'Check snapshot',
              href: 'https://www.shibuya.film/factions/check',
            },
          },
          {
            id: uuid(),
            date: 'July 24, 2023',
            title: 'Chapter 4 Launches',
            description:
              'Chapter 4 launches to the public with an interactive voting experience that will influence the ending of White Rabbit. All participants during the first 2 weeks of launch will be eligible to earn $WRAB (including non-tokenholders).',
            imageSrc:
              '/images/white-rabbit/timeline/normal/chapter-4-launch.jpg',
            wideImageSrc:
              '/images/white-rabbit/timeline/normal/chapter-4-launch.jpg',
            cta: {
              text: 'Watch video',
              href: 'https://www.shibuya.film/shows/white-rabbit/player',
            },
          },
        ],
      },
    ],
  },
  dominionx: {
    id: 'dominionx',
    title: 'Dominion X',
    logoImage: DominionXLogo,
    watchNextShowId: 'white-rabbit',
    creators: 'Steve Aoki and Seth Green’s Stoopid Buddy Stoodios',
    description:
      'Steve Aoki and the studio behind the show "Robot Chicken" brings Dominion X: Level 2 to Shibuya. The six-week event features an interactive stop-motion animation short series.',
    heroVideos: [
      {
        type: 'video/mp4',
        src: 'https://storage.googleapis.com/shibuya-white-rabbit/dominionx_show_page_hero.mp4',
      },
    ],
    thumbnailImageSrc:
      'https://storage.googleapis.com/shibuya-white-rabbit/dominionx-chicken-preview.jpg',
    timelineChapters: [
      {
        id: uuid(),
        title: 'Scene 1',
        events: [
          {
            id: uuid(),
            date: 'Feb 2, 2023',
            title: 'Staking started',
            description:
              'All White Rabbit Keys or Replicant X holders contributed one vote each scene.',
            imageSrc: '/images/dominion-x/timeline/normal/staking-started.jpg',
            wideImageSrc:
              '/images/dominion-x/timeline/wide/staking-started.jpg',
          },
          {
            id: uuid(),
            date: 'Feb 10, 2023',
            title: 'Scene 1 ending vote',
            description:
              'Voting concluded on who won the fight between Chonk and Swole. “Neither” won with 40.46% of the votes, Swole in second with 31.91%, and Chonk with 27.88%.',
            imageSrc:
              '/images/dominion-x/timeline/normal/scene-1-ending-vote.jpg',
            wideImageSrc:
              '/images/dominion-x/timeline/wide/scene-1-ending-vote.jpg',
          },
        ],
      },
      {
        id: uuid(),
        title: 'Scene 2',
        events: [
          {
            id: uuid(),
            date: 'Feb 17, 2023',
            title: 'Scene 2 voting started',
            description:
              'Voting concluded on what the secret weapon characters would use. The “hot dog trebucket” won with 53.84% votes, Zorg ZF-1 came in second with 30.61%, and Bazooka with 15.55%.',
            imageSrc:
              '/images/dominion-x/timeline/normal/scene-2-voting-started.jpg',
            wideImageSrc:
              '/images/dominion-x/timeline/wide/scene-2-voting-started.jpg',
          },
        ],
      },
      {
        id: uuid(),
        title: 'Scene 3',
        events: [
          {
            id: uuid(),
            date: 'Feb 24, 2023',
            title: 'Scene 3 voting ended',
            description:
              'Voting concluded on what Swole does in the next scene. “Dances” wins with 47.58% of the votes, “Tosses Baby X” came in second with 36.69%, and “Panics” with 15.73%.',
            imageSrc:
              '/images/dominion-x/timeline/normal/scene-3-voting-ended.jpg',
            wideImageSrc:
              '/images/dominion-x/timeline/wide/scene-3-voting-ended.jpg',
          },
        ],
      },
      {
        id: uuid(),
        title: 'Scene 4',
        events: [
          {
            id: uuid(),
            date: 'Mar 3, 2023',
            title: 'Scene 4 voting ended',
            description:
              'Voting concluded on what Swole enlists the power of. The huge slingshot won with 37.37% votes, regurgitation came in second with 32.09%, and intense staring with 30.54%.',
            imageSrc:
              '/images/dominion-x/timeline/normal/scene-4-voting-ended.jpg',
            wideImageSrc:
              '/images/dominion-x/timeline/wide/scene-4-voting-ended.jpg',
          },
        ],
      },
      {
        id: uuid(),
        title: 'Scene 5',
        events: [
          {
            id: uuid(),
            date: 'MAR 10, 2023',
            title: 'Scene 5 voting ended',
            description:
              'Voting concluded on how Chonk ends this madness. “With nunchuks” won with 41.63% votes, “with a teddy bear” came in second with 31.12%, and “sits on them” with 27.25%.',
            imageSrc:
              '/images/dominion-x/timeline/normal/scene-5-voting-ended.jpg',
            wideImageSrc:
              '/images/dominion-x/timeline/wide/scene-5-voting-ended.jpg',
          },
          {
            id: uuid(),
            date: 'MAR 16, 2023',
            title: 'Alternative ending competition',
            description:
              'Competition was held to see what voters anticipated the emotions of an alternate ending.',
            imageSrc:
              '/images/dominion-x/timeline/normal/alternative-ending-competition.jpg',
            wideImageSrc:
              '/images/dominion-x/timeline/wide/alternative-ending-competition.jpg',
          },
        ],
      },
      {
        id: uuid(),
        title: 'Scene 6',
        events: [
          {
            id: uuid(),
            date: 'MAR 17, 2023',
            title: 'Scene 6 voting ended',
            description:
              'Voting concluded on the ending of the show. “Weird” won with 56.52% votes, “happy” came in second with 34.91%, and intense staring came in last with 8.58%.',
            imageSrc:
              '/images/dominion-x/timeline/normal/scene-6-voting-ended.jpg',
            wideImageSrc:
              '/images/dominion-x/timeline/wide/scene-6-voting-ended.jpg',
          },
          {
            id: uuid(),
            date: 'MAR 23, 2023',
            title: 'Finale watch party & creator panel',
            description:
              'A finale watch party was hosted to celebrate the ending of the show',
            imageSrc:
              '/images/dominion-x/timeline/normal/finale-watch-party.jpg',
            wideImageSrc:
              '/images/dominion-x/timeline/wide/finale-watch-party.jpg',
          },
          {
            id: uuid(),
            date: 'APR 6, 2023',
            title: 'Frame Polaroids reveal',
            description:
              'People who participated in the voting were assigned an engagement score based on their level of involvement in voting, attending community events and competitions.',
            imageSrc:
              '/images/dominion-x/timeline/normal/frame-nfts-reveal.jpg',
            wideImageSrc:
              '/images/dominion-x/timeline/wide/frame-nfts-reveal.jpg',
          },
        ],
      },
    ],
    behindTheSceneVideos: [
      {
        title: 'Scene 1',
        description:
          'Eric Towner, co-founder at Stoopid Buddy discusses the creative process behind making Dominion X.',
        imageSrc: '/images/dominion-x/behind-the-scenes/scene-1.jpg',
        href: '/shows/dominionx/bts-1',
      },
      {
        title: 'Scene 2',
        description:
          'Eric Towner, co-founder at Stoopid Buddy, and Chris Waters, head of development, walk through the the cadence of the interactive voting process.',
        imageSrc: '/images/dominion-x/behind-the-scenes/scene-2.jpg',
        href: '/shows/dominionx/bts-2',
      },
      {
        title: 'Scene 3',
        description:
          'Learn more about how Eric Towner and Chris Waters’s creative development process and influences on the show.',
        imageSrc: '/images/dominion-x/behind-the-scenes/scene-3.jpg',
        href: '/shows/dominionx/bts-3',
      },
      {
        title: 'Scene 4',
        description:
          'Community members pitch ideas in the Discord channel which influence the interior, set, and character abilities in the show.',
        imageSrc: '/images/dominion-x/behind-the-scenes/scene-4.jpg',
        href: '/shows/dominionx/bts-4',
      },
      {
        title: 'Scene 5',
        description:
          'Eric Towner and Chris Waters break down the team structure and size of those working on the show.',
        imageSrc: '/images/dominion-x/behind-the-scenes/scene-5.jpg',
        href: '/shows/dominionx/bts-5',
      },
      {
        title: 'Scene 6',
        description:
          'Steve Aoki’s music influences the score, picture, and narrative on the show.',
        imageSrc: '/images/dominion-x/behind-the-scenes/scene-6.jpg',
        href: '/shows/dominionx/bts-6',
      },
      {
        title: 'Finale',
        description: 'Final message from the stages.',
        imageSrc: '/images/dominion-x/behind-the-scenes/finale.jpg',
        href: '/shows/dominionx/bts-finale',
      },
    ],
  },
  'house-of-lee': {
    id: 'house-of-lee',
    title: 'House of Lee',
    logoImage: HouseOfLeeLogo,
    watchNextShowId: 'white-rabbit',
    stage: 'trailer',
    thumbnailImageSrc:
      'https://storage.googleapis.com/shibuya-white-rabbit/white-rabbit-preview.png',
    creators: 'Shannon Lee and Shibuya',
    description: 'House of Lee is brought to you by Shannon Lee and Shibuya',
    heroVideos: [
      {
        type: 'video/mp4',
        src: 'https://storage.googleapis.com/shibuya-white-rabbit/HOL_homepage.mp4',
      },
    ],
    heroPrimaryCTA: {
      url: '/shows/house-of-lee/player',
      text: 'Play trailer',
    },
    timelineChapters: [
      {
        id: uuid(),
        title: 'Chapter 1',
        events: [
          {
            id: uuid(),
            date: 'April 12, 2023',
            title: 'House of Lee Campaign Launches',
            description:
              'Shibuya partnered with Shannon Lee to launch House of Lee ticket sales for 36 hours, selling 49k tickets and raising $750,000 for the project',
            imageSrc: '/images/bruce-lee/BL_OE.jpg',
            wideImageSrc: '/images/bruce-lee/BL_OE.jpg',
          },
          {
            id: uuid(),
            date: 'August 13, 2023',
            title: 'Enter the Dragon Re-release / Trailer Premiere',
            description:
              'The House of Lee teaser trailer premiers at over 650 theaters nation wide during the Enter the Dragon re-release for the 50th anniversary of the iconic film.',
            imageSrc: '/images/bruce-lee/bl_premiere.gif',
            wideImageSrc: '/images/bruce-lee/bl_premiere.gif',
          },
        ],
      },
    ],
  },
};
